import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useFormik, FormikProvider } from "formik";
import axios from "axios";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import ReactModal from "react-modal";
import toast from "react-hot-toast";
import { Bars, Oval } from "react-loader-spinner";
import parseHTML from "html-react-parser";
import { HiCheckCircle, HiXCircle, HiXMark } from "react-icons/hi2";
import { AffiliationValidationSchema } from "../../../utils/validation/affiliationSchema";

import santaHat from "../../../images/svg/houx.png";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import PegaseLicense from "../../text/PegaseLicense";
import RgpdPage from "../../text/RgpdPage";
import ConditionsVentesText from "../../text/ConditionsVentes";

import { PriceContext } from "../../../contexts/PriceContext";
import { replaceHtmlYears } from "../../../utils/replaceHtmlYears";

const AffiliationForm = ({ isVisible, promoCode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTvaDialogOpen, setIsTvaDialogOpen] = useState(false);
  const [isTvaCheckDialogOpen, setIsTvaCheckDialogOpen] = useState(false);
  const [isDepositDialogOpen, setIsDepositDialogOpen] = useState(false);
  const [checkingTva, setCheckingTva] = useState(false);
  const [isCheckTvaSuccessful, setIsCheckTvaSuccessful] = useState(false);
  const [isTvaOnWait, setIsTvaOnWait] = useState(false);
  const [validatedVat, setValidatedVat] = useState(null);
  const [isRgpdError, setIsRgpdError] = useState(false);
  const [isTermsError, setIsTermsError] = useState(false);
  const [isContractError, setIsContractError] = useState(false);
  const [isRgpdOpen, setIsRgpdOpen] = useState(false);
  const [isLicenseOpen, setIsLicenseOpen] = useState(false);
  const [isSellsOpen, setIsSellsOpen] = useState(false);
  const [isDoctorManagerCGUOpen, setIsDoctorManagerCGUOpen] = useState(false);
  const [isCaptainVetCGUOpen, setIsCaptainVetCGUOpen] = useState(false);
  const [isCaptainVetCGVOpen, setIsCaptainVetCGVOpen] = useState(false);
  const [isTvaValid, setIsTvaValid] = useState(false);
  const [isViesServiceAvailable, setIsViesServiceAvailable] = useState(false);
  const [isOmvDialogOpen, setIsOmvDialogOpen] = useState(false);
  const [isEmptyOmvDialogOpen, setIsEmptyOmvDialogOpen] = useState(false);

  const [isLoadingPrices, setIsLoadingPrices] = useState(false);
  const [pricesInfo, setPricesInfo] = useState(null);

  const [hasSelectedCountryOnce, setHasSelectedCountryOnce] = useState(false);
  const [selectedPrefix, setSelectedPrefix] = useState("+32");
  const [selectedTVAPrefix, setSelectedTVAPrefix] = useState("BE");

  const [selectedMultiUser, setSelectedMultiUser] = useState(null);
  const [selectedMigrationOption, setSelectedMigrationOption] = useState(null);
  const [selectedCalendarOption, setSelectedCalendarOption] = useState(null);

  const [isSticky, setIsSticky] = useState(false);

  const scrollableDivRef1 = useRef(null);
  const [isButton1Visible, setIsButton1Visible] = useState(true);
  const scrollableDivRef2 = useRef(null);
  const [isButton2Visible, setIsButton2Visible] = useState(true);
  const scrollableDivRef3 = useRef(null);
  const [isButton3Visible, setIsButton3Visible] = useState(true);
  const scrollableDivRef4 = useRef(null);
  const [isButton4Visible, setIsButton4Visible] = useState(true);
  const { priceApi } = useContext(PriceContext);

  const currentYear = new Date().getFullYear();

  const priceFormik = useFormik({
    initialValues: {
      multi_user: null,
      data_migration: null,
      calendar_service: null,
      tva_code: "BE",
      promo_code: promoCode,
      is_young_vet: false,
      graduation_year: null,
    },

    onSubmit: (values) => {
      setIsLoadingPrices(true);

      axios
        .post(process.env.REACT_APP_BACKEND_API_URL + "/total", {
          multi_user: values.multi_user,
          data_migration: values.data_migration,
          calendar_service: values.calendar_service,
          tva_code: values.tva_code,
          promo_code: values.promo_code,
          is_young_vet: values.is_young_vet,
          graduation_year: values.graduation_year,
        })
        .then(function (response) {
          setIsLoadingPrices(false);
          setPricesInfo(response.data);
        })
        .catch(function (error) {
          console.log(error);
          setIsLoadingPrices(false);
        });
    },
  });

  // Function : calculatePriceAPI
  // Get price list from backend based on form choices

  const calculatePriceAPI = useCallback(() => {
    setIsLoadingPrices(true);

    axios
      .post(process.env.REACT_APP_BACKEND_API_URL + "/total/", {
        multi_user: priceFormik.values.multi_user,
        data_migration: priceFormik.values.data_migration,
        calendar_service: priceFormik.values.calendar_service,
        tva_code: priceFormik.values.tva_code,
        promo_code: priceFormik.values.promo_code,
      })
      .then(function (response) {
        setIsLoadingPrices(false);
        setPricesInfo(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoadingPrices(false);
      });
  }, [
    priceFormik.values.multi_user,
    priceFormik.values.data_migration,
    priceFormik.values.calendar_service,
    priceFormik.values.tva_code,
  ]);

  const handleScroll1 = () => {
    if (scrollableDivRef1.current) {
      scrollableDivRef1.current.scrollBy({
        top: 100, // Adjust the scroll amount here
        behavior: "smooth",
      });
    }
  };
  const handleScroll2 = () => {
    if (scrollableDivRef2.current) {
      scrollableDivRef2.current.scrollBy({
        top: 100, // Adjust the scroll amount here
        behavior: "smooth",
      });
    }
  };
  const handleScroll3 = () => {
    if (scrollableDivRef3.current) {
      scrollableDivRef3.current.scrollBy({
        top: 100, // Adjust the scroll amount here
        behavior: "smooth",
      });
    }
  };
  const handleScroll4 = () => {
    if (scrollableDivRef3.current) {
      scrollableDivRef3.current.scrollBy({
        top: 100, // Adjust the scroll amount here
        behavior: "smooth",
      });
    }
  };
  const checkScrollPosition1 = () => {
    if (scrollableDivRef1.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollableDivRef1.current;
      if (scrollHeight <= clientHeight) {
        setIsButton1Visible(false);
      } else {
        if (scrollTop + clientHeight >= scrollHeight) {
          setIsButton1Visible(false);
        } else {
          setIsButton1Visible(true);
        }
      }
    }
  };
  const checkScrollPosition2 = () => {
    if (scrollableDivRef2.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollableDivRef2.current;
      if (scrollHeight <= clientHeight) {
        setIsButton2Visible(false);
      } else {
        if (scrollTop + clientHeight >= scrollHeight) {
          setIsButton2Visible(false);
        } else {
          setIsButton2Visible(true);
        }
      }
    }
  };
  const checkScrollPosition3 = () => {
    if (scrollableDivRef3.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollableDivRef3.current;
      if (scrollHeight <= clientHeight) {
        setIsButton3Visible(false);
      } else {
        if (scrollTop + clientHeight >= scrollHeight) {
          setIsButton3Visible(false);
        } else {
          setIsButton3Visible(true);
        }
      }
    }
  };
  const checkScrollPosition4 = () => {
    if (scrollableDivRef4.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollableDivRef4.current;
      if (scrollHeight <= clientHeight) {
        setIsButton4Visible(false);
      } else {
        if (scrollTop + clientHeight >= scrollHeight) {
          setIsButton4Visible(false);
        } else {
          setIsButton4Visible(true);
        }
      }
    }
  };

  const checkScrollability1 = () => {
    if (scrollableDivRef1.current) {
      const { scrollHeight, clientHeight } = scrollableDivRef1.current;
      setIsButton1Visible(scrollHeight > clientHeight);
      console.log(scrollHeight, clientHeight);
    }
  };
  const checkScrollability2 = () => {
    if (scrollableDivRef2.current) {
      const { scrollHeight, clientHeight } = scrollableDivRef2.current;
      setIsButton2Visible(scrollHeight > clientHeight);
      console.log(scrollHeight, clientHeight);
    }
  };
  const checkScrollability3 = () => {
    if (scrollableDivRef3.current) {
      const { scrollHeight, clientHeight } = scrollableDivRef3.current;
      setIsButton3Visible(scrollHeight > clientHeight);
    }
  };
  const checkScrollability4 = () => {
    if (scrollableDivRef4.current) {
      const { scrollHeight, clientHeight } = scrollableDivRef4.current;
      setIsButton4Visible(scrollHeight > clientHeight);
    }
  };

  useEffect(() => {
    const div = scrollableDivRef1.current;
    checkScrollability1();

    if (div) {
      div.addEventListener("scroll", checkScrollPosition1);
      return () => {
        div.removeEventListener("scroll", checkScrollPosition1);
      };
    }
  }, []);

  useEffect(() => {
    checkScrollability2();

    const div = scrollableDivRef2.current;
    if (div) {
      div.addEventListener("scroll", checkScrollPosition2);
      return () => {
        div.removeEventListener("scroll", checkScrollPosition2);
      };
    }
  }, []);
  useEffect(() => {
    checkScrollability3();

    const div = scrollableDivRef3.current;
    if (div) {
      div.addEventListener("scroll", checkScrollPosition3);
      return () => {
        div.removeEventListener("scroll", checkScrollPosition3);
      };
    }
  }, []);
  useEffect(() => {
    checkScrollability4();

    const div = scrollableDivRef4.current;
    if (div) {
      div.addEventListener("scroll", checkScrollPosition4);
      return () => {
        div.removeEventListener("scroll", checkScrollPosition4);
      };
    }
  }, []);
  useEffect(() => {
    calculatePriceAPI();

    // Function to switch div state based on scroll position
    const handleScroll = (event) => {
      if (window.scrollY >= 220) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [calculatePriceAPI]);

  // Initial form data
  const [formData, setFormData] = useState({
    serverUser: 0,
    appToMigrate: "none",
    firstname: "",
    lastname: "",
    omv: "",
    company: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
    phonePrefix: "+32",
    phone: "",
    email: "",
    deposit: "",
    tvaPrefix: "BE",
    tva: "",
    contract: false,
    terms: false,
    rgpd: false,
    calendarService: "none",
    vat_message: "",
    graduation_year: null,
    is_young_vet: false,
  });

  const formik = useFormik({
    initialValues: {
      serverUser: 0,
      appToMigrate: "none",
      firstname: "",
      lastname: "",
      omv: "",
      company: "",
      address: "",
      country: "",
      city: "",
      zipcode: "",
      phonePrefix: "+32",
      phone: "",
      email: "",
      deposit: "",
      tva: "",
      tvaPrefix: "BE",
      contract: false,
      terms: false,
      rgpd: false,
      is_vat_validated: false,
      calendarService: "none",
      vat_message: "",
      graduation_year: null,
      is_young_vet: false,
    },
    validationSchema: AffiliationValidationSchema,

    // Submit form
    onSubmit: (values) => {
      // Notification - loading
      const subscriptionToastId = toast.loading(
        "Nous traitons votre demande, veuillez patienter..."
      );
      setIsLoading(true);
      let tva = values.tva;
      if (values.tva !== "En attente") {
        if (tva.length === 9) {
          tva = tva.padStart(10, "0");
        }
        tva = values.tvaPrefix + values.tva.replace(/\D/g, "");
      }

      if (validatedVat !== null) {
        tva = validatedVat;
      }

      const formBody = {
        firstname: values.firstname,
        lastname: values.lastname,
        omv: values.omv,
        company: values.company,
        address: values.address,
        country: values.country,
        city: values.city,
        zipcode: values.zipcode,
        phone: values.phonePrefix + values.phone,
        email: values.email,
        tva: tva,
        deposit: values.deposit,
        server_users: values.serverUser,
        migrate_from: values.appToMigrate,
        is_valid: false,
        is_paid: false,
        is_vat_validated: values.is_vat_validated,
        calendar_service: values.calendarService,
        vat_message: values.vat_message,
        tva_code: priceFormik.values.tva_code,
        promo_code: priceFormik.values.promo_code,
        graduation_year: values.graduation_year,
        is_young_vet: values.is_young_vet,
      };
      axios
        .post(
          process.env.REACT_APP_BACKEND_API_URL + "/subscriptions/",
          formBody
        )
        .then(function (response) {
          // Notification - success
          toast.success("Demande envoyée", {
            id: subscriptionToastId,
          });
          setIsLoading(false);
          // Redirection paiement Mollie
          window.location.replace(response.data.url);
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
          toast.error("Erreur veillez ré-essayer ultérieurement", {
            id: subscriptionToastId,
          });
        });
    },
  });

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const cguRef = useRef(null);

  const scrollToCgu = () => {
    if (cguRef.current) {
      cguRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  const [currentStep, setCurrentStep] = useState(1);
  const steps = 3;
  function _renderStepContent(step) {
    switch (step) {
      case 1:
        return (
          <Step1
            formik={formik}
            priceFormik={priceFormik}
            isLicenseOpen={isLicenseOpen}
            setIsLicenseOpen={setIsLicenseOpen}
            isDoctorManagerCGUOpen={isDoctorManagerCGUOpen}
            setIsDoctorManagerCGUOpen={setIsDoctorManagerCGUOpen}
            setIsCaptainVetCGUOpen={setIsCaptainVetCGUOpen}
            isCaptainVetCGUOpen={isCaptainVetCGUOpen}
            setIsCaptainVetCGVOpen={setIsCaptainVetCGVOpen}
            isCaptainVetCGVOpen={isCaptainVetCGVOpen}
            isLoadingPrices={isLoadingPrices}
            pricesInfos={pricesInfo}
            calculatePrice={calculatePriceAPI}
            selectedMultiUser={selectedMultiUser}
            setSelectedMultiUser={setSelectedMultiUser}
            selectedMigrationOption={selectedMigrationOption}
            setSelectedMigrationOption={setSelectedMigrationOption}
            selectedCalendarOption={selectedCalendarOption}
            setSelectedCalendarOption={setSelectedCalendarOption}
          />
        );
      case 2:
        return (
          <Step2
            formik={formik}
            priceFormik={priceFormik}
            setFormData={setFormData}
            formData={formData}
            hasSelectedCountryOnce={hasSelectedCountryOnce}
            setHasSelectedCountryOnce={setHasSelectedCountryOnce}
            selectedPrefix={selectedPrefix}
            setSelectedPrefix={setSelectedPrefix}
            selectedTVAPrefix={selectedTVAPrefix}
            setSelectedTVAPrefix={setSelectedTVAPrefix}
          />
        );
      case 3:
        return (
          <Step3
            formik={formik}
            isContract={isContractError}
            setIsContract={setIsContractError}
            isRgpd={isRgpdError}
            setIsRgpd={setIsRgpdError}
            isTerms={isTermsError}
            setIsTerms={setIsTermsError}
            isRgpdOpen={isRgpdOpen}
            setIsRgpdOpen={setIsRgpdOpen}
            isLicenseOpen={isLicenseOpen}
            setIsLicenseOpen={setIsLicenseOpen}
            isSellsOpen={isSellsOpen}
            setIsSellsOpen={setIsSellsOpen}
            validatedVat={validatedVat}
            cguRef={cguRef}
          />
        );
      default:
        return <div></div>;
    }
  }

  const fieldsArray = [formik.values.firstname, formik.values.lastname];

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  function tvaCheckThenNextStep(e) {
    if (formik.values.tva === "") {
      setIsTvaDialogOpen(true);
    } else {
      tvaValidationThenNextStep(e);
    }
  }

  async function tvaValidationThenNextStep(e) {
    if (formik.values.tva === "En attente") {
      setCheckingTva(false);
      setIsCheckTvaSuccessful(true);
      setIsTvaOnWait(true);
      setIsTvaCheckDialogOpen(true);
    } else {
      setCheckingTva(true);
      setIsTvaCheckDialogOpen(true);

      const countryCode = formik.values.tvaPrefix;
      // on retire les caractères non alphanuméric
      const vatNumber = formik.values.tva.replace(/[^a-z0-9]/gi, "");

      try {
        const response = await axios.post(
          process.env.REACT_APP_BACKEND_API_URL + "/subscriptions/checktva",
          {
            countryCode,
            vatNumber,
          },
          { timeout: 1000 * 20 }
        );

        if (response) {
          setCheckingTva(false);
          setIsCheckTvaSuccessful(true);
          setIsTvaValid(response.data.isVatValidated);
          if (response.data.isVatValidated) {
            setValidatedVat(response.data.fullVatNumber);
          }
          setIsViesServiceAvailable(response.data.isViesServiceAvailable);
          formik.setFieldValue(
            "is_vat_validated",
            response.data.isVatValidated
          );
          formik.setFieldValue("vat_message", response.data.message);
        } else {
          setIsCheckTvaSuccessful(false);
          setCheckingTva(false);
          setIsTvaValid(false);
          formik.setFieldValue("vat_message", "Aucune réponse serveur");
        }
      } catch (error) {
        if (error.code === "ECONNABORTED") {
          setCheckingTva(false);
          setIsTvaValid(false);
          formik.setFieldValue("vat_message", "Aucune réponse serveur");
        }
      }
    }
  }

  function emptyOmvCheckThenNextStep(e) {
    if (formik.values.omv === "") {
      setIsEmptyOmvDialogOpen(true);
    } else {
      omvCheckThenNextStep(e);
    }
  }

  function omvCheckThenNextStep(e) {
    if (formik.values.country === "Belgique") {
      if (formik.values.omv.match(/^\d+$/)) {
        setIsOmvDialogOpen(true);
      } else {
        depositCheckThenNextStep(e);
      }
    } else {
      depositCheckThenNextStep(e);
    }
  }

  function depositCheckThenNextStep(e) {
    if (formik.values.country === "Belgique" && formik.values.deposit === "") {
      setIsDepositDialogOpen(true);
    } else {
      tvaCheckThenNextStep(e);
    }
  }

  function _renderNextButton(step) {
    switch (step) {
      case 1:
        return (
          <button
            type="button"
            onClick={nextStep}
            className="text-base text-white font-normal bg-trinidad-500 py-2 px-12 rounded-full"
          >
            Continuer
          </button>
        );
      case 2:
        return (
          <div>
            <AlertDialog.Root
              open={isTvaCheckDialogOpen}
              className="absolute top-0 left-0"
            >
              <AlertDialog.Portal>
                <AlertDialog.Overlay
                  onClick={() => setIsTvaCheckDialogOpen(false)}
                  className="fixed bg-black bg-opacity-30 w-screen h-screen z-50 top-0 left-0 flex"
                />
                <AlertDialog.Content className="bg-white fixed z-50 mx-auto inset-x-0 bottom-0 sm:top-1/2 sm:bottom-auto sm:-mt-20 sm:max-w-lg rounded-lg p-4 shadow-lg">
                  <AlertDialog.Title className=" font-medium text-lg mb-4">
                    Vérification du numéro de TVA.
                  </AlertDialog.Title>

                  <AlertDialog.Description className=" text-base text-gray-500 font-light mb-8">
                    <ScrollArea.Root className="w-full h-[20vh] max-h-[20vh] sm:h-full sm:max-h-max rounded overflow-hidden  bg-white">
                      <ScrollArea.Viewport
                        className="w-full h-full rounded relative"
                        ref={scrollableDivRef1}
                      >
                        {checkingTva ? (
                          <div className="text-center">
                            <p className="mb-4">Vérification en cours</p>
                            <div className="flex justify-center items-center">
                              <Oval
                                height={80}
                                width={80}
                                color="#002E6D"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#549CFF"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            {!isCheckTvaSuccessful && (
                              <>Erreur serveur ByVets</>
                            )}
                            {isCheckTvaSuccessful && isTvaOnWait && (
                              <p>
                                Numéro de TVA en attente. Vous pouvez continuer
                                la souscription.
                              </p>
                            )}
                            {isCheckTvaSuccessful &&
                              !isViesServiceAvailable &&
                              !isTvaValid &&
                              !isTvaOnWait && (
                                <p>
                                  Impossible de vérifier votre numéro de TVA
                                  pour le moment. Vous pouvez néanmoins
                                  continuer la procédure
                                </p>
                              )}
                            {isCheckTvaSuccessful &&
                              isViesServiceAvailable &&
                              !isTvaValid && (
                                <>
                                  <p className="mb-2">
                                    Votre numéro de TVA n'est pas reconnu par le
                                    système VIES.
                                  </p>
                                  <p className="mb-2">
                                    Assurez-vous que le numéro renseigné ({" "}
                                    {formik.values.tva} ) est bien votre numéro
                                    de TVA.
                                  </p>
                                  <p>Modifiez-le en cas d'erreur.</p>
                                </>
                              )}
                            {isCheckTvaSuccessful &&
                              isViesServiceAvailable &&
                              isTvaValid && (
                                <p>
                                  Votre numéro de TVA a été validé. Vous pouvez
                                  continuer la souscription.
                                </p>
                              )}
                          </>
                        )}
                        <div className="sticky w-full z-50 h-6 bg-gradient-to-b from-transparent to-white bottom-0">
                          <button
                            onClick={handleScroll1}
                            disabled={!isButton1Visible}
                            className={`text-xs justify-center bg-white text-center p-2 border rounded-full cursor-pointer absolute sm:hidden ${
                              isButton1Visible ? "opacity-100" : "opacity-0"
                            } right-0 -top-4 transition-all ease-out`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </ScrollArea.Viewport>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="vertical"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="horizontal"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Corner className="bg-blackA5" />
                    </ScrollArea.Root>
                  </AlertDialog.Description>
                  <div className="flex justify-end gap-x-2">
                    <AlertDialog.Action>
                      {!checkingTva && (
                        <>
                          {!isCheckTvaSuccessful && <>Erreur serveur ByVets</>}
                          {isCheckTvaSuccessful && isTvaOnWait && (
                            <button
                              onClick={(e) => {
                                setIsTvaCheckDialogOpen(false);
                                nextStep(e);
                              }}
                              className="bg-trinidad-100 py-2 px-4 text-trinidad-600 rounded font-medium"
                            >
                              Continuer
                            </button>
                          )}
                          {isCheckTvaSuccessful &&
                            !isViesServiceAvailable &&
                            !isTvaValid &&
                            !isTvaOnWait && (
                              <button
                                onClick={(e) => {
                                  setIsTvaCheckDialogOpen(false);
                                  nextStep(e);
                                }}
                                className="bg-trinidad-100 py-2 px-4 text-trinidad-600 rounded font-medium"
                              >
                                Continuer
                              </button>
                            )}
                          {isCheckTvaSuccessful &&
                            isViesServiceAvailable &&
                            !isTvaValid && (
                              <>
                                <button
                                  onClick={(e) => {
                                    setIsTvaCheckDialogOpen(false);
                                  }}
                                  className="bg-gray-200 py-2 px-4 mr-2 text-gray-600 rounded font-medium"
                                >
                                  Modifier numéro de TVA
                                </button>
                              </>
                            )}
                          {isCheckTvaSuccessful &&
                            isViesServiceAvailable &&
                            isTvaValid && (
                              <button
                                onClick={(e) => {
                                  setIsTvaCheckDialogOpen(false);
                                  nextStep(e);
                                }}
                                className="bg-trinidad-100 py-2 px-4 text-trinidad-600 rounded font-medium"
                              >
                                Continuer
                              </button>
                            )}
                        </>
                      )}
                    </AlertDialog.Action>
                  </div>
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
            <AlertDialog.Root
              open={isTvaDialogOpen}
              className="absolute top-0 left-0"
            >
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="fixed bg-black bg-opacity-30 w-screen h-screen z-50 top-0 left-0 flex" />
                <AlertDialog.Content className="bg-white fixed z-50 mx-auto inset-x-0 bottom-0 sm:top-1/2 sm:bottom-auto sm:-mt-20 sm:max-w-lg rounded-lg p-4 shadow-lg">
                  <AlertDialog.Title className=" font-medium text-lg mb-4">
                    Aucun numéro de TVA renseigné.
                  </AlertDialog.Title>
                  <AlertDialog.Description className=" text-base text-gray-500 font-light mb-8">
                    <ScrollArea.Root className="w-full h-[20vh] max-h-[20vh] sm:h-full sm:max-h-max rounded overflow-hidden  bg-white">
                      <ScrollArea.Viewport
                        className="w-full h-full rounded relative"
                        ref={scrollableDivRef2}
                      >
                        Il est obligatoire pour bénéficier de nos services.
                        Avez-vous un numéro de TVA ou est-il en attente ?
                        <div className="sticky w-full z-50 h-6 bg-gradient-to-b from-transparent to-white bottom-0">
                          <button
                            onClick={handleScroll2}
                            disabled={!isButton2Visible}
                            className={`text-xs justify-center bg-white text-center p-2 border rounded-full cursor-pointer absolute sm:hidden ${
                              isButton2Visible ? "opacity-100" : "opacity-0"
                            } right-0 -top-4 transition-all ease-out`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </ScrollArea.Viewport>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="vertical"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="horizontal"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Corner className="bg-blackA5" />
                    </ScrollArea.Root>
                  </AlertDialog.Description>
                  <div className="flex justify-end gap-x-2">
                    <AlertDialog.Cancel>
                      <button
                        onClick={() => {
                          setIsTvaDialogOpen(false);
                          formik.setFieldValue("tva", "En attente");
                        }}
                        className="bg-gray-200 py-2 px-4 text-gray-600 rounded font-medium"
                      >
                        En attente
                      </button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                      <button
                        onClick={(e) => {
                          setIsTvaDialogOpen(false);
                        }}
                        className="bg-trinidad-100 py-2 px-4 text-trinidad-600 rounded font-medium"
                      >
                        J'ai un numéro
                      </button>
                    </AlertDialog.Action>
                  </div>
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
            <AlertDialog.Root
              open={isDepositDialogOpen}
              className="absolute top-0 left-0"
            >
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="fixed bg-black bg-opacity-30 w-screen h-screen z-50 top-0 left-0 flex" />
                <AlertDialog.Content className="bg-white fixed z-50 mx-auto inset-x-0 bottom-0 sm:top-1/2 sm:bottom-auto sm:-mt-20 sm:max-w-lg rounded-lg p-4 shadow-lg">
                  <AlertDialog.Title className=" font-medium text-lg mb-4">
                    Aucun n° de dépôt de médicaments renseigné.
                  </AlertDialog.Title>
                  <AlertDialog.Description className=" text-base text-gray-500 font-light mb-8">
                    <ScrollArea.Root className="w-full h-[20vh] max-h-[20vh] sm:h-full sm:max-h-max rounded overflow-hidden  bg-white">
                      <ScrollArea.Viewport
                        className="w-full h-full rounded relative"
                        ref={scrollableDivRef2}
                      >
                        La licence est liée au n° de dépôt. Avez-vous un n° de
                        dépôt ou est-il en attente ?
                        <div className="sticky w-full z-50 h-6 bg-gradient-to-b from-transparent to-white bottom-0">
                          <button
                            onClick={handleScroll2}
                            disabled={!isButton2Visible}
                            className={`text-xs justify-center bg-white text-center p-2 border rounded-full cursor-pointer absolute sm:hidden ${
                              isButton2Visible ? "opacity-100" : "opacity-0"
                            } right-0 -top-4 transition-all ease-out`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </ScrollArea.Viewport>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="vertical"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="horizontal"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Corner className="bg-blackA5" />
                    </ScrollArea.Root>
                  </AlertDialog.Description>
                  <div className="flex justify-end gap-x-2">
                    <AlertDialog.Cancel>
                      <button
                        onClick={() => {
                          setIsDepositDialogOpen(false);
                          formik.setFieldValue("deposit", "En attente");
                        }}
                        className="bg-gray-200 py-2 px-4 text-gray-600 rounded font-medium"
                      >
                        En attente
                      </button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                      <button
                        onClick={(e) => {
                          setIsDepositDialogOpen(false);
                        }}
                        className="bg-trinidad-100 py-2 px-4 text-trinidad-600 rounded font-medium"
                      >
                        J'ai un numéro
                      </button>
                    </AlertDialog.Action>
                  </div>
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
            <AlertDialog.Root
              open={isOmvDialogOpen}
              className="absolute top-0 left-0"
            >
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="fixed bg-black bg-opacity-30 w-screen h-screen z-50 top-0 left-0 flex" />
                <AlertDialog.Content className="bg-white fixed z-50 mx-auto inset-x-0 bottom-0 sm:top-1/2 sm:bottom-auto sm:-mt-20 sm:max-w-lg rounded-lg p-4 shadow-lg">
                  <AlertDialog.Title className=" font-medium text-lg mb-4">
                    Vous exercez en Belgique.
                  </AlertDialog.Title>
                  <AlertDialog.Description className=" text-base text-gray-500 font-light mb-8">
                    <ScrollArea.Root className="w-full h-[20vh] max-h-[20vh] sm:h-full sm:max-h-max rounded overflow-hidden  bg-white">
                      <ScrollArea.Viewport
                        className="w-full h-full rounded relative"
                        ref={scrollableDivRef3}
                      >
                        Êtes-vous inscrit à l'Ordre francophone (F...) ou à
                        l'Ordre néerlandophone (N...) ?
                        <div className="sticky w-full z-50 h-6 bg-gradient-to-b from-transparent to-white bottom-0">
                          <button
                            onClick={handleScroll3}
                            disabled={!isButton3Visible}
                            className={`text-xs justify-center bg-white text-center p-2 border rounded-full cursor-pointer absolute sm:hidden ${
                              isButton3Visible ? "opacity-100" : "opacity-0"
                            } right-0 -top-4 transition-all ease-out`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </ScrollArea.Viewport>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="vertical"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="horizontal"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Corner className="bg-blackA5" />
                    </ScrollArea.Root>
                  </AlertDialog.Description>
                  <div className="flex justify-end gap-x-2">
                    <AlertDialog.Action>
                      <button
                        onClick={(e) => {
                          setIsOmvDialogOpen(false);
                          formik.setFieldValue("omv", "N" + formik.values.omv);
                          tvaCheckThenNextStep(e);
                        }}
                        className="bg-gray-200 py-2 px-4 text-gray-600 rounded font-medium"
                      >
                        N{formik.values.omv}
                      </button>
                    </AlertDialog.Action>
                    <AlertDialog.Action>
                      <button
                        onClick={(e) => {
                          setIsOmvDialogOpen(false);
                          formik.setFieldValue("omv", "F" + formik.values.omv);

                          tvaCheckThenNextStep(e);
                        }}
                        className="bg-gray-200 py-2 px-4 text-gray-600 rounded font-medium"
                      >
                        F{formik.values.omv}
                      </button>
                    </AlertDialog.Action>
                  </div>
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>

            <AlertDialog.Root
              open={isEmptyOmvDialogOpen}
              className="absolute top-0 left-0"
            >
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="fixed bg-black bg-opacity-30 w-screen h-screen z-50 top-0 left-0 flex" />
                <AlertDialog.Content className="bg-white fixed z-50 mx-auto inset-x-0 bottom-0 sm:top-1/2 sm:bottom-auto sm:-mt-20 sm:max-w-lg rounded-lg p-4 shadow-lg">
                  <AlertDialog.Title className=" font-medium text-lg mb-4">
                    Aucun numéro à l'ordre (OMV) renseigné.
                  </AlertDialog.Title>
                  <AlertDialog.Description className=" text-base text-gray-500 font-light mb-8">
                    <ScrollArea.Root className="w-full h-[20vh] max-h-[20vh] sm:h-full sm:max-h-max rounded overflow-hidden  bg-white">
                      <ScrollArea.Viewport
                        className="w-full h-full rounded relative"
                        ref={scrollableDivRef4}
                      >
                        Vous devez être vétérinaire pour bénéficier de nos
                        services. Avez-vous un numéro à l'ordre ?
                        <div className="sticky w-full z-50 h-6 bg-gradient-to-b from-transparent to-white bottom-0">
                          <button
                            onClick={handleScroll4}
                            disabled={!isButton4Visible}
                            className={`text-xs justify-center bg-white text-center p-2 border rounded-full cursor-pointer absolute sm:hidden ${
                              isButton4Visible ? "opacity-100" : "opacity-0"
                            } right-0 -top-4 transition-all ease-out`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5"
                              />
                            </svg>
                          </button>
                        </div>
                      </ScrollArea.Viewport>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="vertical"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Scrollbar
                        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
                        orientation="horizontal"
                      >
                        <ScrollArea.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
                      </ScrollArea.Scrollbar>
                      <ScrollArea.Corner className="bg-blackA5" />
                    </ScrollArea.Root>
                  </AlertDialog.Description>
                  <div className="flex justify-end gap-x-2">
                    <AlertDialog.Cancel>
                      <button
                        onClick={(e) => {
                          setIsEmptyOmvDialogOpen(false);
                          omvCheckThenNextStep(e);
                        }}
                        className="bg-gray-200 py-2 px-4 text-gray-600 rounded font-medium"
                      >
                        Non
                      </button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action>
                      <button
                        onClick={(e) => {
                          setIsEmptyOmvDialogOpen(false);
                        }}
                        className="bg-trinidad-100 py-2 px-4 text-trinidad-600 rounded font-medium"
                      >
                        Oui
                      </button>
                    </AlertDialog.Action>
                  </div>
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
            <button
              type="button"
              disabled={
                fieldsArray.includes("") || !isEmptyObject(formik.errors)
              }
              onClick={(e) => depositCheckThenNextStep(e)}
              className="text-base text-white font-normal bg-trinidad-500 py-2 px-12 rounded-full disabled:opacity-50"
            >
              Continuer
            </button>
          </div>
        );
      case 3:
        return (
          <button
            onClick={() => {
              if (
                formik.values.is_young_vet &&
                new Date().getFullYear() - formik.values.graduation_year <= 5
              ) {
                if (!(formik.values.rgpd && formik.values.terms)) {
                  scrollToCgu();
                  if (!formik.values.terms) {
                    setIsTermsError(true);
                  }
                  if (!formik.values.contract) {
                    setIsContractError(true);
                  }
                } else {
                  formik.submitForm();
                }
              } else {
                if (
                  !(
                    formik.values.rgpd &&
                    formik.values.terms &&
                    formik.values.contract
                  )
                ) {
                  scrollToCgu();
                  if (!formik.values.rgpd) {
                    setIsRgpdError(true);
                  }
                  if (!formik.values.terms) {
                    setIsTermsError(true);
                  }
                  if (!formik.values.contract) {
                    setIsContractError(true);
                  }
                } else {
                  formik.submitForm();
                }
              }
            }}
            type="button"
            disabled={isLoading}
            className="text-base text-white font-normal bg-trinidad-500 py-2 px-12 rounded-full disabled:opacity-50"
          >
            {!isLoading && <>Valider et payer</>}
            {isLoading && (
              <Bars
                heigth="20"
                width="20"
                color="white"
                className="opacity-60"
                ariaLabel="loading-indicator"
              />
            )}
          </button>
        );
      default:
        return null;
    }
  }

  const nextStep = (e) => {
    e.preventDefault();
    if (currentStep < steps) {
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, 0);
    }
  };
  const previousStep = (e) => {
    e.preventDefault();
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={isLicenseOpen}
        onRequestClose={() => setIsLicenseOpen(false)}
        overlayElement={
          (props, contentElement) => (
            <div {...props}>
              <div
                className="flex justify-end mt-2 mx-auto text-gray-400"
                style={{ maxWidth: "1300px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className=" flex-col ">{contentElement}</div>
            </div>
          )
          /* Custom Overlay element. */
        }
        preventScroll={true}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 100,
          },
          content: {
            position: "absolute",
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "40px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "12px",
            outline: "none",
            padding: "20px",
            maxWidth: "1300px",
            margin: "auto",
          },
        }}
      >
        <PegaseLicense />
        <div className="flex justify-end py-4 gap-4">
          <button
            onClick={() => setIsLicenseOpen(false)}
            className="bg-gray-100 py-2 px-8 rounded-full text-gray-500"
          >
            Retour
          </button>
          <button
            className=" bg-trinidad-500 py-2 px-8 rounded-full text-white"
            onClick={() => {
              formik.setFieldValue("terms", true);
              setIsLicenseOpen(false);
            }}
          >
            Accepter
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isRgpdOpen}
        onRequestClose={() => setIsRgpdOpen(false)}
        preventScroll={true}
        overlayElement={
          (props, contentElement) => (
            <div {...props}>
              <div
                className="flex justify-end mt-2 mx-auto text-gray-400"
                style={{ maxWidth: "1300px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className=" flex-col ">{contentElement}</div>
            </div>
          )
          /* Custom Overlay element. */
        }
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 100,
          },
          content: {
            position: "absolute",
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "40px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "12px",
            outline: "none",
            padding: "20px",
            maxWidth: "1300px",
            margin: "auto",
          },
        }}
      >
        <RgpdPage />
        <div className="flex justify-end py-4 gap-4">
          <button
            onClick={() => setIsRgpdOpen(false)}
            className="bg-gray-100 py-2 px-8 rounded-full text-gray-500"
          >
            Retour
          </button>
          <button
            className=" bg-trinidad-500 py-2 px-8 rounded-full text-white"
            onClick={() => {
              formik.setFieldValue("rgpd", true);
              setIsRgpdOpen(false);
            }}
          >
            Accepter
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isSellsOpen}
        onRequestClose={() => setIsSellsOpen(false)}
        preventScroll={true}
        overlayElement={
          (props, contentElement) => (
            <div {...props}>
              <div
                className="flex justify-end mt-2 mx-auto text-gray-400"
                style={{ maxWidth: "1300px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className=" flex-col ">{contentElement}</div>
            </div>
          )
          /* Custom Overlay element. */
        }
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 100,
          },
          content: {
            position: "absolute",
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "40px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "12px",
            outline: "none",
            padding: "20px",
            maxWidth: "1300px",
            margin: "auto",
          },
        }}
      >
        <ConditionsVentesText />
        <div className="flex justify-end py-4 gap-4">
          <button
            className=" bg-trinidad-500 py-2 px-8 rounded-full text-white"
            onClick={() => {
              setIsSellsOpen(false);
            }}
          >
            Fermer
          </button>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isDoctorManagerCGUOpen}
        onRequestClose={() => setIsDoctorManagerCGUOpen(false)}
        preventScroll={true}
        overlayElement={
          (props, contentElement) => (
            <div {...props}>
              <div
                className="flex justify-end mt-2 mx-auto text-gray-400 z-[200]"
                style={{ maxWidth: "1300px", height: "100px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className=" flex-col ">{contentElement}</div>
            </div>
          )
          /* Custom Overlay element. */
        }
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 100,
          },
          content: {
            position: "absolute",
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "40px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "12px",
            outline: "none",
            padding: "20px",
            maxWidth: "1300px",
            margin: "auto",
          },
        }}
      >
        <iframe
          src="https://doctormanager.be/conditions-utilisation"
          title="Conditions d'utilisation de DoctorManager"
          frameborder="0"
          width={"100%"}
          height={"100%"}
        ></iframe>
      </ReactModal>
      <ReactModal
        isOpen={isCaptainVetCGVOpen}
        onRequestClose={() => setIsCaptainVetCGVOpen(false)}
        preventScroll={true}
        overlayElement={
          (props, contentElement) => (
            <div {...props}>
              <div
                className="flex justify-end mt-2 mx-auto text-gray-400"
                style={{ maxWidth: "1300px", height: "100px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className=" flex-col ">{contentElement}</div>
            </div>
          )
          /* Custom Overlay element. */
        }
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 100,
          },
          content: {
            position: "absolute",
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "40px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "12px",
            outline: "none",
            padding: "20px",
            maxWidth: "1300px",
            margin: "auto",
          },
        }}
      >
        <iframe
          src="https://www.captainvet.com/cgv"
          title="Conditions d'utilisation de CaptainVet"
          frameborder="0"
          width={"100%"}
          height={"100%"}
        ></iframe>
      </ReactModal>
      <ReactModal
        isOpen={isCaptainVetCGUOpen}
        onRequestClose={() => setIsCaptainVetCGUOpen(false)}
        preventScroll={true}
        overlayElement={
          (props, contentElement) => (
            <div {...props}>
              <div
                className="flex justify-end mt-2 mx-auto text-gray-400"
                style={{ maxWidth: "1300px", height: "100px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <div className=" flex-col ">{contentElement}</div>
            </div>
          )
          /* Custom Overlay element. */
        }
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 100,
          },
          content: {
            position: "absolute",
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "40px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "12px",
            outline: "none",
            padding: "20px",
            maxWidth: "1300px",
            margin: "auto",
          },
        }}
      >
        <iframe
          src="https://www.captainvet.com/cgu"
          title="Conditions d'utilisation de CaptainVet"
          frameborder="0"
          width={"100%"}
          height={"100%"}
        ></iframe>
      </ReactModal>

      <div className="flex justify-between">
        <div className="flex items-center mb-8">
          <div className="stepper">1</div>
          <div
            className={`stepper-divider ${
              currentStep >= 2 ? "" : "opacity-50"
            }`}
          >
            {" "}
          </div>
          <div
            className={`stepper  ${currentStep >= 2 ? "" : "opacity-50"}   `}
          >
            2
          </div>
          <div
            className={`stepper-divider ${
              currentStep >= 3 ? "" : "opacity-50"
            }`}
          >
            {" "}
          </div>

          <div
            className={`stepper  ${currentStep >= 3 ? "" : "opacity-50"}   `}
          >
            3
          </div>
        </div>
        {priceApi && priceApi.offer.event === "veterinexpo" && (
          <>
            {" "}
            <div
              className="big_red_promo w-[375px] h-[150px] relative bg-[#f32100] text-center text-white border border-trinidad-500 p-4 rotate-[9deg]"
              style={{ borderRadius: "188px / 75px" }}
            >
              <div className="absolute top-[-13px] left-[75px] -scale-x-100 -rotate-12">
                <img
                  src={santaHat}
                  alt="Décoration fin d'année"
                  width={"36px"}
                />
              </div>
              <h4 className="text-xl py-1 flex font-extrabold justify-center tracking-wide">
                Action Vétérinexpo
              </h4>
              <p className="font-medium">
                <span className="text-lg tracking-wide">
                  Souscrivez maintenant
                </span>
                <br /> ►►► bénéficiez de l’année {currentYear + 1} <br /> au
                tarif {currentYear} + fin {currentYear} gratuite
              </p>
            </div>
          </>
        )}
      </div>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-full flex text-midnight-blue-500">
            <div className="flex-1 md:pr-4 font-light">
              {_renderStepContent(currentStep)}
            </div>
            <div className="hidden md:block relative affiliation-bill">
              <div
                className={` mt-10 transition-all  ${
                  isSticky ? "fixed top-[50px]" : "relative"
                }`}
              >
                <div className=" bg-white shadow-lg rounded-xl p-2 w-[415px]">
                  {isLoadingPrices && (
                    <div className="absolute rounded-xl opacity-95 bg-white top-0 left-0 right-0 bottom-0 min-w-[415px]">
                      <div className="opacity-100 flex justify-center items-center h-full">
                        <Oval
                          height={80}
                          width={80}
                          color="#002E6D"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#549CFF"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      </div>
                    </div>
                  )}
                  <h2 className="p-2  text-2xl font-semibold">Ma commande</h2>
                  <div className="flex-col flex-wrap items-center">
                    <div className="flex flex-wrap items-center p-2">
                      <div className="flex-1 pr-4 font-semibold">
                        Souscription annuelle{" "}
                        {/* {pricesInfo && pricesInfo.offer.event === "veterinexpo"
                          ? new Date().getFullYear() + 1
                          : new Date().getFullYear()} */}
                      </div>
                      <div className="text-right pl-4">
                        {pricesInfo &&
                          pricesInfo.subscription_price
                            .toFixed(2)
                            .replace(".", ",") + " €"}{" "}
                      </div>
                    </div>
                    {pricesInfo && pricesInfo.offer.value && (
                      <div className="flex gap-x-2 flex-wrap items-center rounded p-2 pt-0 mb-2 text-trinidad-500">
                        <div className="flex-1 font-normal gap-x-2">
                          {pricesInfo && pricesInfo.offer.html_label_website ? (
                            <div>
                              {parseHTML(
                                replaceHtmlYears(
                                  pricesInfo.offer.html_label_website
                                )
                              )}
                            </div>
                          ) : (
                            pricesInfo.offer.value
                          )}
                          {/* {pricesInfo && pricesInfo.offer.value} */}
                        </div>
                        <div className=" text-right">
                          {pricesInfo &&
                            pricesInfo.offer.price !== 0 &&
                            pricesInfo.offer.price
                              .toFixed(2)
                              .replace(".", ",")
                              .replace("-", "- ") + " €"}
                        </div>
                      </div>
                    )}
                    {pricesInfo &&
                      pricesInfo.young_vet &&
                      pricesInfo.young_vet.amount > 0 && (
                        <div className="flex gap-x-2 flex-wrap items-center rounded p-2 pt-0  text-trinidad-500">
                          <div className="flex-1 font-normal gap-x-2">
                            Action spéciale jeune vétérinaire
                            {/* {pricesInfo && pricesInfo.offer.value} */}
                          </div>
                          <div className=" text-right">
                            -
                            {pricesInfo &&
                              pricesInfo.young_vet.amount !== 0 &&
                              pricesInfo.young_vet.amount
                                .toFixed(2)
                                .replace(".", ",")
                                .replace("-", "- ") + " €"}
                          </div>
                        </div>
                      )}

                    {pricesInfo && (
                      <div>
                        {(pricesInfo.multi_user.price > 0 ||
                          formik.values.appToMigrate !== "none" ||
                          pricesInfo.calendar_service.price > 0) && (
                          <h4 className="font-semibold text-base p-2">
                            Options{" "}
                            <span className="text-xs font-normal">
                              (paiement unique)
                            </span>
                          </h4>
                        )}
                      </div>
                    )}

                    {pricesInfo && pricesInfo.multi_user.price > 0 && (
                      <div
                        className="flex flex-wrap items-center rounded p-2 my-2"
                        style={{ background: "#BFDAFF" }}
                      >
                        <div className="w-1/2 font-semibold">
                          Version serveur <br /> jusque{" "}
                          <span className="text-trinidad-500">
                            {pricesInfo && pricesInfo.multi_user.value}
                          </span>{" "}
                          utilisateurs
                        </div>
                        <div className="w-1/2 text-right">
                          {numberWithCommas(
                            pricesInfo.multi_user.price
                              .toFixed(2)
                              .toString()
                              .replace(".", ",")
                          )}
                           €
                        </div>
                      </div>
                    )}
                    {formik.values.appToMigrate &&
                      formik.values.appToMigrate !== "none" && (
                        <div className="flex flex-wrap items-center rounded bg-orangish-500 p-2 my-2">
                          <div className="w-1/2 font-semibold">
                            Récupération des données (
                            <span className=" capitalize text-trinidad-500">
                              {formik.values.appToMigrate === "other" ? (
                                "autre"
                              ) : (
                                <>
                                  {pricesInfo &&
                                    pricesInfo.data_migration.value}
                                </>
                              )}
                            </span>
                            )
                          </div>
                          <div className="w-1/2 text-right">
                            {formik.values.appToMigrate !== "other" ? (
                              <span>
                                {numberWithCommas(
                                  pricesInfo.data_migration.price
                                    .toFixed(2)
                                    .toString()
                                    .replace(".", ",")
                                )}
                                 €{" "}
                              </span>
                            ) : (
                              <span>Sur devis</span>
                            )}
                          </div>
                        </div>
                      )}
                    {pricesInfo && pricesInfo.calendar_service.price > 0 && (
                      <div className="flex flex-wrap items-center rounded bg-orangish-500 p-2 my-2">
                        <div className="w-1/2 font-semibold">
                          Service de rdv en ligne (
                          <span className=" capitalize text-trinidad-500">
                            {pricesInfo && pricesInfo.calendar_service.value}
                          </span>
                          )
                        </div>
                        <div className="w-1/2 text-right">
                          <span>
                            {pricesInfo &&
                              pricesInfo.calendar_service.price.toLocaleString(
                                "fr-FR",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  useGrouping: true,
                                }
                              )}
                             €{" "}
                          </span>
                        </div>
                      </div>
                    )}
                    {promoCode !== null && (
                      <div className="flex flex-wrap items-center p-2 font-semibold">
                        Code promo
                        {pricesInfo && pricesInfo.promo_code_is_valid && (
                          <div className="flex items-center justify-between w-full">
                            <div className="flex justify-between items-center border  border-green-700 bg-green-50  text-green-700 rounded-lg text-sm py-2.5 px-3 mt-2  gap-x-2">
                              <div className="flex items-center">
                                {/* <div className="mr-2 text-2xl">
                                  <HiCheckCircle />
                                </div> */}
                                <div>
                                  <p>{pricesInfo.promo_code}</p>
                                  <p className="text-xs">
                                    {pricesInfo.promo_code_label}
                                  </p>
                                </div>
                              </div>
                              <div className="text-lg">
                                <button
                                  onClick={() => {
                                    priceFormik.setFieldValue("promo_code", "");
                                    priceFormik.submitForm();
                                  }}
                                >
                                  <HiXMark />
                                </button>
                              </div>
                            </div>
                            <div className="font-normal">
                              {pricesInfo.promo_code_discount_value.toLocaleString(
                                "fr-FR",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  useGrouping: true,
                                }
                              )}
                              €
                            </div>
                          </div>
                        )}
                        {pricesInfo &&
                          pricesInfo.promo_code_is_valid !== true && (
                            <div className="flex w-full justify-between items-center border  border-gray-300  rounded-lg text-sm py-2.5 px-3 mt-2  ">
                              <input
                                type="text"
                                onChange={(e) =>
                                  priceFormik.setFieldValue(
                                    "promo_code",
                                    e.target.value
                                  )
                                }
                                className="focus:outline-none"
                                value={priceFormik.values.promo_code}
                              />
                              <button
                                className="text-sm "
                                onClick={priceFormik.submitForm}
                              >
                                Appliquer
                              </button>
                            </div>
                          )}
                        {pricesInfo && (
                          <div>
                            {pricesInfo.promo_code !== null &&
                              (!pricesInfo.promo_code_exists ||
                                !pricesInfo.promo_code_is_valid) && (
                                <div className="flex items-center text-red-500 font-normal ml-3 gap-x-1">
                                  <HiXCircle /> Code promo non valide
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="flex flex-wrap items-center p-2">
                      <div className="w-1/2 pr-4 font-semibold">Total HTVA</div>
                      <div className="w-1/2 text-right pl-4">
                        {pricesInfo &&
                          pricesInfo.total_htva.toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}
                         €
                      </div>
                    </div>
                    {pricesInfo && pricesInfo.vat_amount > 0 && (
                      <div className="flex flex-wrap items-center p-2">
                        <div className="w-1/2 pr-4 font-semibold">TVA</div>
                        <div className="w-1/2 text-right pl-4">
                          {pricesInfo &&
                            pricesInfo.vat_amount.toLocaleString("fr-FR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              useGrouping: true,
                            })}
                           €
                        </div>
                      </div>
                    )}
                    <div className="flex flex-wrap items-center p-2">
                      <div className="w-1/2 pr-4 font-semibold">TOTAL</div>
                      <div className="w-1/2 text-right pl-4">
                        {pricesInfo &&
                          pricesInfo.total.toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}
                         €
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex  gap-x-6 justify-center items-center mt-20">
                  <div className=" text-center">
                    {currentStep > 1 && (
                      <button type="button" onClick={previousStep}>
                        Revenir en arrière
                      </button>
                    )}
                  </div>
                  <div className=" text-center">
                    {_renderNextButton(currentStep)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden mb-8">
            <div className="mt-5 lg:hidden text-midnight-blue-500">
              <div className=" bg-white shadow-lg rounded-xl p-2">
                {isLoadingPrices && (
                  <div className="absolute rounded-xl opacity-95 bg-white top-0 left-0 right-0 bottom-0 min-w-[415px]">
                    <div className="opacity-100 flex justify-center items-center h-full">
                      <Oval
                        height={80}
                        width={80}
                        color="#002E6D"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#549CFF"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  </div>
                )}
                <h2 className="p-2  text-2xl font-semibold">Ma commande</h2>
                <div className="flex-col flex-wrap items-center">
                  <div className="flex flex-wrap items-center p-2">
                    <div className="flex-1 pr-4 font-semibold">
                      Souscription annuelle{" "}
                      {/* {pricesInfo && pricesInfo.offer.event === "veterinexpo"
                          ? new Date().getFullYear() + 1
                          : new Date().getFullYear()} */}
                    </div>
                    <div className="text-right pl-4">
                      {pricesInfo &&
                        pricesInfo.subscription_price
                          .toFixed(2)
                          .replace(".", ",") + " €"}{" "}
                    </div>
                  </div>
                  {pricesInfo && pricesInfo.offer.value && (
                    <div className="flex gap-x-2 flex-wrap items-center rounded p-2 pt-0 mb-2 text-trinidad-500">
                      <div className="flex-1 font-normal gap-x-2">
                        {pricesInfo && pricesInfo.offer.html_label_website ? (
                          <div>
                            {parseHTML(
                              replaceHtmlYears(
                                pricesInfo.offer.html_label_website
                              )
                            )}
                          </div>
                        ) : (
                          pricesInfo.offer.value
                        )}
                        {/* {pricesInfo && pricesInfo.offer.value} */}
                      </div>
                      <div className=" text-right">
                        {pricesInfo &&
                          pricesInfo.offer.price !== 0 &&
                          pricesInfo.offer.price
                            .toFixed(2)
                            .replace(".", ",")
                            .replace("-", "- ") + " €"}
                      </div>
                    </div>
                  )}

                  {pricesInfo && (
                    <div>
                      {(pricesInfo.multi_user.price > 0 ||
                        formik.values.appToMigrate !== "none" ||
                        pricesInfo.calendar_service.price > 0) && (
                        <h4 className="font-semibold text-base p-2">
                          Options{" "}
                          <span className="text-xs font-normal">
                            (paiement unique)
                          </span>
                        </h4>
                      )}
                    </div>
                  )}

                  {pricesInfo && pricesInfo.multi_user.price > 0 && (
                    <div
                      className="flex flex-wrap items-center rounded p-2 my-2"
                      style={{ background: "#BFDAFF" }}
                    >
                      <div className="w-1/2 font-semibold">
                        Version serveur <br /> jusque{" "}
                        <span className="text-trinidad-500">
                          {pricesInfo && pricesInfo.multi_user.value}
                        </span>{" "}
                        utilisateurs
                      </div>
                      <div className="w-1/2 text-right">
                        {numberWithCommas(
                          pricesInfo.multi_user.price
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")
                        )}
                         €
                      </div>
                    </div>
                  )}
                  {formik.values.appToMigrate &&
                    formik.values.appToMigrate !== "none" && (
                      <div className="flex flex-wrap items-center rounded bg-orangish-500 p-2 my-2">
                        <div className="w-1/2 font-semibold">
                          Récupération des données (
                          <span className=" capitalize text-trinidad-500">
                            {formik.values.appToMigrate === "other" ? (
                              "autre"
                            ) : (
                              <>
                                {pricesInfo && pricesInfo.data_migration.value}
                              </>
                            )}
                          </span>
                          )
                        </div>
                        <div className="w-1/2 text-right">
                          {formik.values.appToMigrate !== "other" ? (
                            <span>
                              {numberWithCommas(
                                pricesInfo.data_migration.price
                                  .toFixed(2)
                                  .toString()
                                  .replace(".", ",")
                              )}
                               €{" "}
                            </span>
                          ) : (
                            <span>Sur devis</span>
                          )}
                        </div>
                      </div>
                    )}
                  {pricesInfo && pricesInfo.calendar_service.price > 0 && (
                    <div className="flex flex-wrap items-center rounded bg-orangish-500 p-2 my-2">
                      <div className="w-1/2 font-semibold">
                        Service de rdv en ligne (
                        <span className=" capitalize text-trinidad-500">
                          {pricesInfo && pricesInfo.calendar_service.value}
                        </span>
                        )
                      </div>
                      <div className="w-1/2 text-right">
                        <span>
                          {pricesInfo &&
                            pricesInfo.calendar_service.price.toLocaleString(
                              "fr-FR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                useGrouping: true,
                              }
                            )}
                           €{" "}
                        </span>
                      </div>
                    </div>
                  )}
                  {promoCode !== null && (
                    <div className="flex flex-wrap items-center p-2 font-semibold">
                      Code promo
                      {pricesInfo && pricesInfo.promo_code_is_valid && (
                        <div className="flex items-center justify-between w-full">
                          <div className="flex justify-between items-center border  border-green-700 bg-green-50  text-green-700 rounded-lg text-sm py-2.5 px-3 mt-2  gap-x-2">
                            <div className="flex items-center">
                              {/* <div className="mr-2 text-2xl">
                                  <HiCheckCircle />
                                </div> */}
                              <div>
                                <p>{pricesInfo.promo_code}</p>
                                <p className="text-xs">
                                  {pricesInfo.promo_code_label}
                                </p>
                              </div>
                            </div>
                            <div className="text-lg">
                              <button
                                onClick={() => {
                                  priceFormik.setFieldValue("promo_code", "");
                                  priceFormik.submitForm();
                                }}
                              >
                                <HiXMark />
                              </button>
                            </div>
                          </div>
                          <div className="font-normal">
                            {pricesInfo.promo_code_discount_value.toLocaleString(
                              "fr-FR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                useGrouping: true,
                              }
                            )}
                            €
                          </div>
                        </div>
                      )}
                      {pricesInfo &&
                        pricesInfo.promo_code_is_valid !== true && (
                          <div className="flex w-full justify-between items-center border  border-gray-300  rounded-lg text-sm py-2.5 px-3 mt-2  ">
                            <input
                              type="text"
                              onChange={(e) =>
                                priceFormik.setFieldValue(
                                  "promo_code",
                                  e.target.value
                                )
                              }
                              className="focus:outline-none"
                              value={priceFormik.values.promo_code}
                            />
                            <button
                              className="text-sm "
                              onClick={priceFormik.submitForm}
                            >
                              Appliquer
                            </button>
                          </div>
                        )}
                      {pricesInfo && (
                        <div>
                          {pricesInfo.promo_code !== null &&
                            (!pricesInfo.promo_code_exists ||
                              !pricesInfo.promo_code_is_valid) && (
                              <div className="flex items-center text-red-500 font-normal ml-3 gap-x-1">
                                <HiXCircle /> Code promo non valide
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="flex flex-wrap items-center p-2">
                    <div className="w-1/2 pr-4 font-semibold">Total HTVA</div>
                    <div className="w-1/2 text-right pl-4">
                      {pricesInfo &&
                        pricesInfo.total_htva.toLocaleString("fr-FR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          useGrouping: true,
                        })}
                       €
                    </div>
                  </div>
                  {pricesInfo && pricesInfo.vat_amount > 0 && (
                    <div className="flex flex-wrap items-center p-2">
                      <div className="w-1/2 pr-4 font-semibold">TVA</div>
                      <div className="w-1/2 text-right pl-4">
                        {pricesInfo &&
                          pricesInfo.vat_amount.toLocaleString("fr-FR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}
                         €
                      </div>
                    </div>
                  )}
                  <div className="flex flex-wrap items-center p-2">
                    <div className="w-1/2 pr-4 font-semibold">TOTAL</div>
                    <div className="w-1/2 text-right pl-4">
                      {pricesInfo &&
                        pricesInfo.total.toLocaleString("fr-FR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                          useGrouping: true,
                        })}
                       €
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center mt-20">
                {currentStep > 1 && (
                  <div className="flex-1 text-center">
                    <button type="button" onClick={previousStep}>
                      Revenir en arrière
                    </button>
                  </div>
                )}
                <div className="flex-1 text-center">
                  {_renderNextButton(currentStep)}
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};

export default AffiliationForm;
