import * as Yup from "yup";

Yup.addMethod(Yup.string, "tvaNumber", function (errorMessage) {
  return this.test(`test-tva-number`, errorMessage, function (value) {
    const { path, createError } = this;

    const checkTva = async (value) => {
      if (value === undefined || value === "En attente") {
        return true;
      } else {
        return true;
      }
    };

    return checkTva(value) || createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, "phoneValidation", function (errorMessage) {
  return this.test(`phone-validation`, errorMessage, function (value) {
    const { path, createError } = this;

    const checkPhone = async (value) => {
      if (value != null) {
        const whiteSpacesCount = value.split(" ").length - 1;

        const charCount = value.length - whiteSpacesCount;
        if (charCount < 7 || charCount > 11) {
          return false;
        } else {
          return true;
        }
      }
    };

    return checkPhone(value) || createError({ path, message: errorMessage });
  });
});

export const AffiliationValidationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Requis"),
  lastname: Yup.string()
    .min(2, "Trop court!")
    .max(50, "Trop long!")
    .required("Requis"),
  address: Yup.string().required("Requis"),
  country: Yup.string().required("Requis"),
  city: Yup.string().required("Requis"),
  zipcode: Yup.number().typeError("Chiffres uniquement").required("Requis"),
  phone: Yup.string()
    .phoneValidation("Veuillez entrer un numéro valide")
    // .min(7, "Le numéro de téléphone est trop court")
    // .max(12, "Le numéro de téléphone est trop long")
    .required("Requis"),
  email: Yup.string()
    .email("Veuillez entrer une adresse email valide")
    .required("Requis"),
  tva: Yup.string().tvaNumber("Numéro TVA invalide"),
  graduation_year: Yup.string()
    .nullable()
    .when("is_young_vet", {
      is: true,
      then: () => Yup.string().length(4, "4 chiffres").required("Requis"),
      otherwise: () => Yup.string().nullable(),
    }),
  is_young_vet: Yup.boolean().required("Requis"),
  omv: Yup.string().required("Requis"),
});
