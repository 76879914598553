import React from "react";
import PasswordProtect from "../components/helpers/PasswordProtect";
import Layout from "../components/layout/Layout";
import VideoList from "../components/videos/VideoList";

const VideosPage = () => {
  return (
    <PasswordProtect correctPassword={process.env.REACT_APP_VIDEOS_PASSWORD}>
      <Layout>
        <section className=" mb-24">
          <h2 className="text-center text-midnight-blue-500 font-semibold text-3xl mb-8">
            Découvrez nos tutoriels vidéo
          </h2>
          <VideoList />
          {/* <div className="text-center">
              <div>Plus de vidéos</div>
            </div> */}
        </section>
      </Layout>
    </PasswordProtect>
  );
};

export default VideosPage;
