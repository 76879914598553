import React, { useState, useEffect, useContext } from "react";

import logo from "../../images/icon.png";

import { Link, redirect, useLocation } from "react-router-dom";
import HeaderLink from "../links/HeaderLink";
import { PriceContext } from "../../contexts/PriceContext";
import santaHat from "../../images/svg/santaHat.svg";
import { browserName } from "react-device-detect";
import boule from "../../images/svg/newBoule.png";
import HeaderButton from "../buttons/HeaderButton";
import { useAuth } from "react-oidc-context";
import MobileMenuModal from "../modals/MobileMenuModal";
import FocusTracker from "../helpers/FocusTracker";
import { setPreviousPath } from "../../utils/previousPath";
import MyAccountButton from "../buttons/MyAccountButton";

const Header = () => {
  const auth = useAuth();
  const location = useLocation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [isBallHidden, setIsBallHidden] = useState(false);
  const url = typeof window !== "undefined" ? window.location.href : "";
  const urlArray = url.split("/");
  const urlPath = urlArray[urlArray.length - 1];
  const { priceApi, isLoadingApiPrice, fetchPriceData } =
    useContext(PriceContext);
  // const navigate = useNavigate();

  useEffect(() => {
    // Function to switch div state based on scroll position
    fetchPriceData();
    const handleScroll = (event) => {
      if (window.scrollY >= 100) {
        setIsBallHidden(true);
      } else {
        setIsBallHidden(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerLinks = [
    {
      text: "Accueil",
      link: "/",
      secured: false,
    },
    {
      text: "Pégase",
      link: "/pegase",
      secured: false,
    },

    {
      text: "Adhésion",
      link: "/souscrire",
      secured: {
        text: "Support",
        link: "/support",
      },
    },
    // {
    //   text: "News",
    //   link: "/news",
    //   secured: false,
    // },
    {
      text: "Contacts",
      link: "/contacts",
    },
  ];

  return (
    <>
      <MobileMenuModal
        open={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        headerLinks={headerLinks}
      />
      <div
        className={` ${isMobileMenuOpen ? "hidden" : ""} fixed w-full z-[99] `}
      >
        <div className=" container mx-auto header flex bg-white justify-between rounded-full pl-2">
          <div className=" px-2 mx-2">
            <div className="flex items-center h-full">
              <Link to="/">
                <img src={logo} className="header-logo" alt="Logo Byvets" />
              </Link>
            </div>
          </div>
          <div className=" px-2 mx-2 flex">
            <div className="flex-none hidden lg:flex items-center">
              {headerLinks.map((hl) => {
                if (hl.secured) {
                  if (auth.isAuthenticated) {
                    return (
                      <HeaderLink key={hl.secured.text} to={hl.secured.link}>
                        {hl.secured.text}
                      </HeaderLink>
                    );
                  } else {
                    if (hl.link === "/souscrire") {
                      return (
                        <div className={`relative `} key={hl.text}>
                          <div
                            className={`absolute ${
                              priceApi && priceApi.offer.event === "veterinexpo"
                                ? "block"
                                : "hidden"
                            } top-[-3px] left-[13px] -scale-x-100 -rotate-[45deg] `}
                          >
                            <img src={santaHat} alt="" width={"14px"} />
                          </div>

                          {priceApi &&
                            priceApi.offer.event === "veterinexpo" && (
                              <Link
                                to={"/souscrire"}
                                className={`${
                                  location.pathname === "/" ? "block" : "hidden"
                                } ${
                                  isBallHidden
                                    ? "-translate-y-100"
                                    : "translate-y-0"
                                } transition-all duration-1000 ease-in-out absolute w-[200px] h-[200px] top-0 left-0 ml-[-17px] ${
                                  browserName === "Safari" ? "mt-6" : "mt-6"
                                } cursor-pointer`}
                                style={{
                                  backgroundImage: `url(${boule})`,
                                  backgroundSize: "cover",
                                }}
                                // onClick={() => {
                                //   console.log("test");
                                //   redirect("/souscrire");
                                // }}
                              ></Link>
                            )}

                          <HeaderLink key={hl.text} to={hl.link}>
                            {hl.text}
                          </HeaderLink>
                        </div>
                      );
                    }
                    return (
                      <HeaderLink key={hl.text} to={hl.link}>
                        {hl.text}
                      </HeaderLink>
                    );
                  }
                }

                return (
                  <HeaderLink key={hl.text} to={hl.link}>
                    {hl.text}
                  </HeaderLink>
                );
              })}
            </div>
            <div className="flex items-center">
              {auth && (
                <>
                  {auth.isAuthenticated ? (
                    <>
                      <HeaderButton
                        className="hidden lg:inline-block"
                        secondary
                        tel
                        to="tel:+32476252536"
                      >
                        +32 476 25 25 36
                      </HeaderButton>
                      <button
                        type="button"
                        className="bg-trinidad-500 font-normal text-white rounded-full px-3 py-2 mx-2 lg:px-6 lg:py-2 lg:mx-2 text-sm lg:text-base"
                        onClick={() => {
                          auth.signoutSilent();
                        }}
                      >
                        Se déconnecter
                      </button>
                      {/* <MyAccountButton /> */}
                    </>
                  ) : (
                    <>
                      <HeaderButton
                        className="hidden lg:inline-block relative"
                        secondary
                        tel
                        to="tel:+32476252536"
                      >
                        <div className="absolute text-[11px] top-0 left-0 bg-white -mt-2 ml-[22px] px-1 tracking-wider">
                          Support
                        </div>
                        +32 476 25 25 36
                      </HeaderButton>
                      <HeaderButton
                        onClick={() => {
                          setPreviousPath();
                          auth.clearStaleState();
                          auth.signinRedirect();
                        }}
                      >
                        Me connecter
                      </HeaderButton>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="flex flex-col items-center lg:hidden">
              <button
                className="flex flex-col h-full justify-center mx-2 "
                onClick={() => setIsMobileMenuOpen(true)}
              >
                <span className=" bg-trinidad-500 w-6 h-1 rounded-sm"></span>
                <span className=" bg-trinidad-500 w-6 h-1 my-1 rounded-sm"></span>
                <span className=" bg-trinidad-500 w-6 h-1 rounded-sm"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
