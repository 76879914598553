import * as Yup from "yup";

Yup.addMethod(Yup.string, "phoneValidation", function (errorMessage) {
  return this.test(`phone-validation`, errorMessage, function (value) {
    const { path, createError } = this;

    const checkPhone = async (value) => {
      if (value != null) {
        const whiteSpacesCount = value.split(" ").length - 1;

        const charCount = value.length - whiteSpacesCount;
        if (charCount < 7 || charCount > 11) {
          return false;
        } else {
          return true;
        }
      }
    };

    return checkPhone(value) || createError({ path, message: errorMessage });
  });
});

export const DemoValidationSchema = Yup.object().shape(
  {
    firstname: Yup.string()
      .min(2, "Trop court!")
      .max(50, "Trop long!")
      .required("Requis"),
    lastname: Yup.string()
      .min(2, "Trop court!")
      .max(50, "Trop long!")
      .required("Requis"),
    email: Yup.string()
      .email()
      .when("phone", {
        is: (phone) => !phone || phone.length === 0,
        then: () =>
          Yup.string().email("Adresse email incorrecte").required("Requis"),
        otherwise: () => Yup.string(),
      }),
    phone: Yup.string().when("email", {
      is: (email) => !email || email.length === 0,
      then: () =>
        Yup.string()
          .phoneValidation("Veuillez entrer un numéro valide")
          .required("Requis"),
      otherwise: () => Yup.string(),
    }),

    message: Yup.string().required("Requis"),
    rgpd: Yup.bool().oneOf(
      [true],
      "Vous devez accepter la Politique de Protection Vie Privée pour poursuivre."
    ),
  },
  [["email", "phone"]]
);
