import { useState } from "react";
import logo from "../../images/icon.png";
import { useFormik } from "formik";

const PasswordProtect = ({ correctPassword, children }) => {
  const [input, setInput] = useState("");
  const [accessGranted, setAccessGranted] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
    },

    onSubmit: (values) => {
      //   if (values.password === correctPassword) {
      //     setAccessGranted(true);
      //   } else {
      //     alert("Mot de passe incorrect !");
      //   }
      console.log("test");
    },
  });
  const checkPassword = () => {
    if (formik.values.password === correctPassword) {
      setAccessGranted(true);
    } else {
      alert("Mot de passe incorrect !");
    }
  };

  return accessGranted ? (
    children
  ) : (
    // <div className="flex flex-col items-center justify-center h-screen">
    //   <h2 className="text-xl mb-4">Entrez le mot de passe :</h2>
    //   <input
    //     type="password"
    //     value={input}
    //     onChange={(e) => setInput(e.target.value)}
    //     className="p-2 border rounded"
    //   />
    //   <button
    //     onClick={checkPassword}
    //     className="mt-2 p-2 bg-blue-500 text-white rounded"
    //   >
    //     Valider
    //   </button>
    // </div>
    <div className="min-h-screen">
      <div class="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <img class="mx-auto h-10 w-auto" src={logo} alt="Your Company" />
          <h2 class="mt-10 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
            Accès aux vidéos
          </h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div class="space-y-6">
            <div>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="block text-sm/6 font-medium text-gray-900"
                >
                  Mot de passe
                </label>
              </div>
              <div class="mt-2">
                <input
                  type="password"
                  name="password"
                  id="password"
                  autocomplete="current-password"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                />
              </div>
            </div>

            <div>
              <button
                onClick={() => checkPassword()}
                class="flex w-full justify-center rounded-md bg-trinidad-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-trinidad-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-trinidad-600"
              >
                Se connecter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordProtect;
