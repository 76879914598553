import React, { useState } from "react";
import { useFormik, FormikProvider } from "formik";
import ValidationError from "../../components/forms/ValidationError";

import { Link } from "react-router-dom";

import axios from "axios";
import toast from "react-hot-toast";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/seo/SEO";
import { DemoValidationSchema } from "../../utils/validation/demoSchema";

const ContactDemoPage = () => {
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [hasSentMessage, setHasSentMessage] = useState(false);
  const [messageCommunication, setMessageCommunication] = useState("");

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      message: "Je souhaite assister à une démo. Mes disponibilités sont : ",
      address2: "",
      rgpd: false,
    },
    validationSchema: DemoValidationSchema,

    onSubmit: (values) => {
      setMessageCommunication("Envoi en cours...");
      setHasSentMessage(false);

      if (values.address2 === "") {
        setIsSendingMail(true);
        axios
          .post(process.env.REACT_APP_BACKEND_API_URL + "/mail/demo/", {
            firstname: formik.values.firstname,
            lastname: formik.values.lastname,
            phone: formik.values.phone,
            email: formik.values.email,
            message: formik.values.message,
            title: "Demande de démo du logiciel Pégase",
          })
          .then(function (response) {
            toast.success("Message envoyé", {
              position: "bottom-center",
            });
            setMessageCommunication("Envoyé");
            setIsSendingMail(false);
            setHasSentMessage(true);
          })
          .catch(function (error) {
            toast.error("Erreur. Veuillez réessayer.", {
              position: "bottom-center",
            });
            setMessageCommunication("Erreur. Veuillez réessayer.");
            setIsSendingMail(false);
            setHasSentMessage(false);
          });
      }
    },
  });

  const handleInputChange = () => {
    if (hasSentMessage) {
      setMessageCommunication("");
      setIsSendingMail(false);
      setHasSentMessage(false);
    }
  };

  return (
    <Layout>
      <SEO />

      <section className="mb-36">
        <h3 className="text-midnight-blue-500 sm:text-center text-3xl sm:text-4xl font-semibold mb-11">
          Demande de démo
        </h3>
        <div className="w-full sm:w-7/12 sm:mx-auto">
          <FormikProvider value={formik}>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col sm:flex-row flex-wrap "
            >
              <div className="w-full sm:w-1/2 sm:pr-2 mb-6">
                <label
                  htmlFor="firstname"
                  className="block text-xs text-midnight-blue-500 font-medium pl-2"
                >
                  Prénom
                </label>
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  onChange={(e) => {
                    handleInputChange();
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname}
                  className="border border-trinidad-500 w-full rounded-lg text-base py-1 px-4"
                />
                {formik.errors.firstname && formik.touched.firstname ? (
                  <ValidationError>{formik.errors.firstname}</ValidationError>
                ) : null}
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2 mb-6">
                <label
                  htmlFor="lastname"
                  className="block text-xs text-midnight-blue-500 font-medium sm:pl-2"
                >
                  Nom
                </label>
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  onChange={(e) => {
                    handleInputChange();
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname}
                  className="border border-trinidad-500 w-full rounded-lg text-base py-1 px-4"
                />
                {formik.errors.lastname && formik.touched.lastname ? (
                  <ValidationError>{formik.errors.lastname}</ValidationError>
                ) : null}
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2 mb-6 hidden">
                <label
                  htmlFor="adress2"
                  className="block text-xs text-midnight-blue-500 font-medium sm:pl-2"
                >
                  Adresse
                </label>
                <input
                  id="address2"
                  name="address2"
                  type="text"
                  onChange={(e) => {
                    handleInputChange();
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.address2}
                  className="border border-trinidad-500 w-full rounded-lg text-base py-1 px-4"
                />
                {formik.errors.address2 && formik.touched.address2 ? (
                  <ValidationError>{formik.errors.address2}</ValidationError>
                ) : null}
              </div>
              <div className=" w-full sm:w-1/2 sm:pr-2 mb-6">
                <label
                  htmlFor="email"
                  className="block text-xs text-midnight-blue-500 font-medium sm:pl-2"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={(e) => {
                    handleInputChange();
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="border border-trinidad-500 w-full rounded-lg text-base py-1 px-4"
                />
                {formik.errors.email && formik.touched.email ? (
                  <ValidationError>{formik.errors.email}</ValidationError>
                ) : null}
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2 mb-6">
                <label
                  htmlFor="phone"
                  className="block text-xs text-midnight-blue-500 font-medium sm:pl-2"
                >
                  Téléphone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  onChange={(e) => {
                    const phoneValue = e.target.value.replace(/[^\d\s]/g, "");
                    formik.setFieldValue("phone", phoneValue);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  className="border border-trinidad-500 w-full rounded-lg text-base py-1 px-4"
                />
                {formik.errors.phone && formik.touched.phone ? (
                  <ValidationError>{formik.errors.phone}</ValidationError>
                ) : null}
              </div>
              <div className="w-full  mb-6">
                <label
                  htmlFor="phone"
                  className="block text-xs text-midnight-blue-500 font-medium pl-2"
                >
                  Questions, remarques, disponibilités
                </label>
                <textarea
                  id="message"
                  name="message"
                  onChange={(e) => {
                    handleInputChange();
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  className="border border-trinidad-500 w-full rounded-lg text-base py-1 px-4 h-32"
                />
                {formik.errors.message && formik.touched.message ? (
                  <ValidationError>{formik.errors.message}</ValidationError>
                ) : null}
              </div>
              <div className="flex  text-midnight-blue-500 mb-9">
                <label className="control control-checkbox w-11/12 ">
                  J'ai pris connaissance de la{" "}
                  <Link to="/">Politique de Protection Vie Privée (RGDP)</Link>{" "}
                  et j'accepte que mes données soient traitées en lien avec ma
                  demande.
                  <input
                    type="checkbox"
                    name="rgpd"
                    id="rgpd"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rgpd}
                  />
                  {formik.errors.rgpd && formik.touched.rgpd ? (
                    <div className="-ml-2 mt-2">
                      <ValidationError>{formik.errors.rgpd}</ValidationError>
                    </div>
                  ) : null}
                  <div className="control_indicator"></div>
                </label>
              </div>
              <div className="text-center w-full">
                <button
                  disabled={isSendingMail || hasSentMessage}
                  type="submit"
                  className="text-base text-white font-normal bg-trinidad-500 py-2 px-12 rounded-full disabled:opacity-50 disabled:cursor-not-allowed transition-all"
                >
                  {isSendingMail && "Envoi en cours..."}
                  {!isSendingMail && hasSentMessage && "Message envoyé"}
                  {!isSendingMail && !hasSentMessage && "Envoyer"}
                </button>
              </div>
            </form>
          </FormikProvider>
        </div>
      </section>
    </Layout>
  );
};

export default ContactDemoPage;
