import React, { useContext } from "react";

import illuFormation from "../../images/formations/formationsAvIllu.png";
import SchoolHatIcon from "../../images/icons/SchoolHatIcon";

import FormationTable from "../../components/table/FormationTable";
import { useAuth } from "react-oidc-context";
import { HashLink } from "react-router-hash-link";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/seo/SEO";
import Unauthorized from "../../components/layout/Unauthorized";
import { PriceContext } from "../../contexts/PriceContext";
import { formatPrice } from "../../utils/formatPrice";

const FormationsPage = () => {
  const auth = useAuth();
  const { priceApi } = useContext(PriceContext);

  if (!auth.isAuthenticated) {
    return <Unauthorized />;
  }

  return (
    <Layout>
      <SEO />

      <section className="flex  flex-col  w-full text-midnight-blue-500 rounded-lg px-4 justify-center max-w-2xl mx-auto">
        <div className="w-16 mx-auto mt-4 animate-wiggle hover:animate-none fill-trinidad-500">
          <SchoolHatIcon />
        </div>
        <div className="flex justify-center text-midnight-blue-500 text-[48px] mb-4">
          <h2 className="my-4 px-4 text-center text-midnight-blue-500 text-3xl font-semibold leading-normal ">
            Découvrez nos formations
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-x-16 mx-auto mb-4 ">
          <HashLink
            to="#gratuites"
            className="group text-center shadow-xl rounded-3xl pb-2 px-8 pt-5 cursor-pointer self-start"
          >
            <div>
              <div className="mb-2 text-lg font-semibold">
                Formations gratuites
              </div>
              <div className="text-sm font-light">
                {" "}
                Déstinée aux nouveaux utilisateurs
              </div>
              <div className=" invisible group-hover:visible text-center mt-4 opacity-0 group-hover:opacity-100  transition-all mb-2">
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto"
                >
                  <path
                    d="M11.2989 21.9816L19.1726 14.1079C19.5254 13.7551 19.7236 13.2766 19.7236 12.7777C19.7236 12.2788 19.5254 11.8003 19.1726 11.4475C18.8199 11.0947 18.3414 10.8965 17.8424 10.8965C17.3435 10.8965 16.865 11.0947 16.5122 11.4475L11.8364 16.0965L11.8364 1.89794C11.8364 1.39904 11.6382 0.920578 11.2854 0.567804C10.9327 0.215031 10.4542 0.0168453 9.95529 0.0168453C9.4564 0.0168453 8.97793 0.215031 8.62516 0.567804C8.27239 0.920578 8.0742 1.39904 8.0742 1.89794L8.0742 16.0965L3.42521 11.4475C3.06886 11.0947 2.58696 10.8979 2.08551 10.9005C1.58407 10.903 1.10417 11.1046 0.751377 11.4609C0.398586 11.8173 0.201805 12.2992 0.204324 12.8006C0.206844 13.3021 0.408458 13.782 0.764814 14.1348L8.61165 21.9816C8.96755 22.3396 9.45051 22.5425 9.95529 22.546C10.2062 22.5504 10.4552 22.5025 10.6866 22.4054C10.9179 22.3082 11.1265 22.1639 11.2989 21.9816Z"
                    fill="#E65300"
                  />
                </svg>
              </div>
            </div>
          </HashLink>
          <HashLink
            to="#mesures"
            className="group text-center shadow-xl rounded-3xl pb-2 px-8 pt-5 cursor-pointer self-start transition-all"
          >
            <div>
              <div className="mb-2 text-lg font-semibold">
                Formations sur mesure
              </div>
              <div className="text-sm font-light">
                Selon les besoins de votre cabinets
              </div>
              <div className=" invisible group-hover:visible text-center mt-4 opacity-0 group-hover:opacity-100 transition-all mb-2">
                <svg
                  width="20"
                  height="23"
                  viewBox="0 0 20 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-auto"
                >
                  <path
                    d="M11.2989 21.9816L19.1726 14.1079C19.5254 13.7551 19.7236 13.2766 19.7236 12.7777C19.7236 12.2788 19.5254 11.8003 19.1726 11.4475C18.8199 11.0947 18.3414 10.8965 17.8424 10.8965C17.3435 10.8965 16.865 11.0947 16.5122 11.4475L11.8364 16.0965L11.8364 1.89794C11.8364 1.39904 11.6382 0.920578 11.2854 0.567804C10.9327 0.215031 10.4542 0.0168453 9.95529 0.0168453C9.4564 0.0168453 8.97793 0.215031 8.62516 0.567804C8.27239 0.920578 8.0742 1.39904 8.0742 1.89794L8.0742 16.0965L3.42521 11.4475C3.06886 11.0947 2.58696 10.8979 2.08551 10.9005C1.58407 10.903 1.10417 11.1046 0.751377 11.4609C0.398586 11.8173 0.201805 12.2992 0.204324 12.8006C0.206844 13.3021 0.408458 13.782 0.764814 14.1348L8.61165 21.9816C8.96755 22.3396 9.45051 22.5425 9.95529 22.546C10.2062 22.5504 10.4552 22.5025 10.6866 22.4054C10.9179 22.3082 11.1265 22.1639 11.2989 21.9816Z"
                    fill="#E65300"
                  />
                </svg>
              </div>
            </div>
          </HashLink>
        </div>
      </section>
      <div id="gratuites" className="pt-16 pb-8"></div>

      <section className="mb-12 ">
        <h2 className="text-center text-midnight-blue-500 font-semibold text-3xl mb-6">
          Nos formations gratuites
        </h2>
        <h3 className="text-center mb-2 ">
          Nous offrons 2 demi-journées à tout nouvel utilisateur{" "}
        </h3>
        <p className="text-center mb-2">Une personne par cabinet </p>
        <p className="text-center mb-4 text-sm">
          Pour suivre une formation pour plus d'une personne,{" "}
          <span className="underline">
            <HashLink to="#mesures">
              consultez nos formations sur mesure
            </HashLink>
          </span>
        </p>
        {/* <h4 className="text-center text-xl mb-8 text-trinidad-500 font-light">
              Découvrez nos prochaines formations{" "}
              <span className="font-semibold">offertes</span> aux nouveaux
              utilisateurs
            </h4> */}
        <div className="max-w-2xl border mx-auto py-4 px-4 rounded mb-8">
          <h3 className="text-midnight-blue-300 text-center uppercase font-semibold text-xs tracking-widest">
            Informations
          </h3>
          <h4 className="text-center  text-base  font-semibold mt-2 mb-4">
            Quand :
            <span className="text-center font-normal  text-base mb-8">
              {" "}
              De 9h à 12h (3h + pauses)
            </span>
          </h4>
          <h4 className="flex text-center  text-base  font-semibold mt-2 mb-4 justify-center ">
            <div>Où :</div>
            <div className="text-left font-normal text-base ml-1">
              {" "}
              Chez le Dr. Alain de Gottal
              <br />
              Cabinet Vétérinaire Ben-Ahin
              <br />
              Rue Joseph Wauters 67, 4500 Huy
            </div>
          </h4>
        </div>
        <FormationTable />

        <div id="mesures" className="pt-16 pb-8"></div>

        <div className="max-w-[680px] mx-auto text-center">
          <h2 className="text-center text-midnight-blue-500 font-semibold text-3xl mb-8  mt-16">
            Nos formations sur mesures
          </h2>
          <p className="text-center text-lg font-semibold">
            Besoin d’une formation adaptée aux besoins de votre cabinet ?{" "}
          </p>
          <p className="mt-4 text-xl font-semibold text-trinidad-500 mb-8">
            <span className="font-normal mr-2"></span> Organisons une formation
            sur base de vos demandes !
          </p>
          <div className="max-w-2xl border mx-auto py-4 px-4 rounded mb-8">
            <h3 className="text-midnight-blue-300 text-center uppercase font-semibold text-xs tracking-widest mb-4">
              Description
            </h3>
            <p className="text-center font-normal text-sm mb-8">
              Une formation sur mesure, pour toute votre équipe et à votre
              cabinet, vous permettra de gagner du temps et économiser de
              l'argent grâce à un encodage plus rapide, plus efficace et donc
              plus performant.
            </p>
            <img
              src={illuFormation}
              width={"50%"}
              height={"50%"}
              className="mt-8  mx-auto"
              alt=""
            />
            <p className="text-center font-normal text-sm mb-4">
              Création de fiches médicaments, encodage du stock, gestion des
              commandes, mise en place de la comptabilité, formation des
              assistantes vétérinaires, ...
            </p>
            <p className="mb-1 italic text-neutral-400 text-xs">
              Formations à distance possible selon les sujets abordés.
            </p>
          </div>
          <div className="max-w-2xl border mx-auto py-4 px-4 rounded mb-8">
            <h3 className="text-midnight-blue-300 text-center uppercase font-semibold text-xs tracking-widest mb-4">
              Tarifs (HTVA)
            </h3>
            {!!priceApi && (
              <div className="flex flex-col max-w-sm mx-auto mb-4 gap-x-4">
                <div className="flex justify-between font-normal text-sm">
                  <div className="text-left">Tarif horaire</div>
                  <div className="text-right ">
                    {formatPrice(priceApi.formations_heure)} €/heure
                  </div>
                </div>
                <div className="flex justify-between font-normal text-sm">
                  <div className="text-left ">Frais de déplacement</div>
                  <div className="text-right ">
                    {formatPrice(priceApi.formations_km)} €/km +{" "}
                    {formatPrice(priceApi.formations_move_hourly)} €/heure
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="max-w-2xl border mx-auto py-4 px-4 rounded mb-8">
            <h3 className="text-midnight-blue-300 text-center uppercase font-semibold text-xs tracking-widest mb-4">
              En pratique
            </h3>
            <p className="text-center font-normal text-sm mb-2">
              Pour mettre au point cette formation
            </p>
            <HashLink
              to="/contacts#administrateurs"
              className="text-sm sm:text-base flex items-center justify-center content-button bg-trinidad-500 text-white font-light rounded-full text-center py-4 px-4 sm:p-2 cursor-pointer"
            >
              Contactez Alain de Gottal
            </HashLink>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FormationsPage;
